.container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slider {
  position: absolute;
  cursor: ew-resize;

  width: 40px;
  height: 40px;
  background-color: #F32196;
  opacity: 0.7;
  border-radius: 50%;

  top: calc(50% - 20px);
  left: calc(50% - 20px);
}