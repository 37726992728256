.menu1 {
  display: flex;
  background-color: rgba(255, 255, 255, 0.8); /* Color de fondo del botón */
  position: absolute;
  justify-content: flex-start; 
  left:1px; /* Ajusta la distancia desde el lado izquierdo según tu preferencia */
  top: none;
    
}
.menu {
  background-color: #00B4CB;
  height: 100vh;
  width: 100px;
  border: none;
  
}

.submenu { 
  display: flex;
  background-color: black;
  margin-left: 100px;   
 }

.submenu1 {
  margin-top: 73px;
  align-items:center;
}

.submenu2 { 
  display: flex;
  background-color: rgba(0, 0, 0, 0.0); /* Color de fondo del botón */
  flex-direction: column;
  align-items: center; 
  height:270px ;
  width: 100px;
  padding-top: 94px;
  border:aqua;
  
}

.boton6 {
  display: flex;
  flex-direction: column;
  align-items: center; 
  background-color: rgb(255, 255, 255);
  padding: 27px;
  width: 100px;
  height: 90px;
  
  cursor: pointer;
  border: none;
  outline: none;   
  }
  .boton6:hover{
    background-color: #ebe0e0;
  }
.boton7 {
  display: flex;
  flex-direction: column;
  align-items: center; 
  background-color: rgb(255, 255, 255);
  width: 100px;
  height: 90px;
  padding: 25px;
  }

.boton8 {
  align-items: center; 
  width: 47px;
  height: 50px;
  cursor: pointer;
  
 }

.boton9 {
  
  align-items: center; 
  background-color: rgba(255, 255, 255);
  width: 95px;
  height:100px ;
  padding-top: 10px;
  cursor: pointer;
  border: none;
  outline: none;  
  }
.boton9:hover{
    background-color: #ebe0e0;
  }


.mapa-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 100px;
  z-index: -1; /* Valor negativo para el mapa-container */
  background: rgba(255, 255, 255, 0.8);
}
  
  
.BotonMenu{
  background-color: #00B4CB;
  width: 100px;
  height: 90px;
  cursor: pointer;
  border: none;
  outline: none; 
 }
 .BotonMenu:active {
  background-color: #010507; /* Color cuando el botón está activo */
  color: #8a2121; /* Color del texto cuando el botón está activo */
}
.BotonMenu:hover {
  background-color: #ffffff; /* Color cuando el botón está activo */
  color: #8a2121; /* Color del texto cuando el botón está activo */
}
  
 .BotonMenu1 {
  background-color: rgb(255, 255, 255);
  width: 100px;
  height: 90px;
  cursor: pointer;
  border: none;
  outline: none;  
 }

 .BotonMenu1:hover{
  background-color: #ebe0e0;
}
.logo{
  background-color:white;
  height: 90px;
  width: 100px;
  top:none;
}
.Logoo{
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  padding-left: auto;
 
  background-color: #3498db;
  border: none;
  color: #923f3f; 
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
 
}
.boton-circular {
  position: absolute;
  top: 430px;
  left: 580px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo del botón */
  border: none; /* Elimina el borde */
  border-radius: 50%; /* Establece el radio para hacerlo circular */
  color: #ffffff; /* Color del texto */
  font-size: 1px; /* Tamaño de fuente del texto */
  text-align: center; /* Alineación del texto en el centro */
  text-decoration: none; /* Quita subrayado enlaces */
  display: inline-block;
  cursor: pointer;
 }

.boton-circular:hover {
  background-color: #2980b9; /* Cambia el color de fondo al pasar el ratón sobre el botón */
}

.boton-circular1{
  position: absolute;
  top: 3%; /* Centra verticalmente */
  left: 155%; /* Centra horizontalmente */
  width: 800px;
  height: 550px;
  display: inline-block;
  cursor: pointer;
 }
 .boton-circular2 {
  position: absolute;
  top: 2%; /* Centra verticalmente */
  left: 153%; /* Centra horizontalmente */
  width: 800px;
  height: 549px;
  display: inline-block;
  cursor: pointer;
}



.Logo1{
  height: 55px;
  width: auto;
}
.Logo2{
  height: 55px;
  width: auto;
 }
.Logo3{
  height: 43px;
  width:auto;
  }
.Logo4{
  height: 50px;
  width:auto;
  cursor: pointer;
  }

.Logo5{
    display:inline; 
    height: 30px;
    width:70px;
    cursor: pointer;
    margin:5px ;
    margin-left: 1px;
  }
.Logo5:hover{
    background-color: #3498db;
  }
.Logo6{
      display:flex; 
      position:relative;
      flex-direction: column;
      height: 30px;
      width: 95px;
      cursor: pointer;
      align-items:center;
      background-color: blue(211, 24, 24, 0.7); /* Color de fondo del botón */
      margin: 2px;
     
      }
.Logo6:hover {
        background-color: #2980b9; /* Cambia el color de fondo al pasar el ratón sobre el botón */
      }


.bienvenido{ 
   background-color: none;
   padding: 11px;
   font-size: 14px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;
   
}


.imagen360-container {
  position: absolute;
  top: 330px;
  left: none;
  width: 100%;
  height: 100%;
  background: rgb(192, 84, 84);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.GoogleBoton {
  display: flex;
  background-color: rgba(255, 255, 255, 0.5); /* Color de fondo del botón */
  flex-direction: column;
  align-items: center; 
  height:270px ;
  width: 100px;
  padding-left:none ;
  padding-top: 94px;
  z-index: 1000;
}

.BotonMenusalir {
  background: #ff0000;
  color: #ffffff;
  padding: none;
  /* border: none; */
  cursor: pointer;
  position:absolute; /* Posición absoluta en relación al contenedor padre */
  bottom: none; /* Distancia desde la parte inferior de la pantalla */
  z-index: 1001; /* Asegura que esté por encima del contenedor */
}
/* codigo */
.BotonMenusalir1 {
  background: #ff0000;
  color: #ffffff;
  padding: none;
  border: none;
  cursor: pointer;
  position:relative; /* Posición absoluta en relación al contenedor padre */
  bottom: none; /* Distancia desde la parte inferior de la pantalla */
  z-index: 1001; /* Asegura que esté por encima del contenedor */
}
/* codigo */
.boton360 {
  position:fixed;
  top: 10px;
  right:10px ;
  z-index: 999; /* Asegura que el botón esté en la parte superior */
  background-color: #534848; /* Color de fondo rojo (puedes ajustarlo) */
  color: #ffffff; /* Color de texto blanco (puedes ajustarlo) */
  padding: 10px;
  cursor: pointer;
  border: none; /* Elimina el borde */
  border-radius: 40%; 
}
.boton360:hover {
  background-color: #b49494; /* Cambia el color de fondo al pasar el ratón sobre el botón */
}

.text1{
  font-size: 17;
  color: #000000;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;
}
.text2{
  font-size: 40;
  color: #000000;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;
}

.text3{
  font-size: 40;
  color: #000000;
  font-size: 12px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;

}
.containerPrueba{

  width: 100px;
  height: 100px;
}
/* Porgramacion para te\xto de Bienvenida */

.welcome-screen {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.welcome-content {
  position: relative;
  text-align: center;
  z-index: 2;
  padding: auto;
}

.welcome-content h1 {
  font-size: 1.5rem;
  margin-bottom: 0px;
  position: absolute; /* Posicionamiento absoluto */
  transform: translate(40%, 300%); /* Ajusta el centro del botón */
}

.welcome-content img {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  opacity: 0.5;
  z-index: 1;
}

.welcome-content button {
  padding: 10px 50px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: absolute; /* Posicionamiento absoluto */
  top: 50%; /* Centra verticalmente */
  left: 50%; /* Centra horizontalmente */
  transform: translate(-50%, -50%); /* Ajusta el centro del botón */
  z-index: 3;
}

.welcome-content button:hover {
  background-color: #0056b3;
}
 .ingresar  {
  
}