.menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .menu-button {
    background-color: #031120; /* Color de fondo del botón */
    color: #fff; /* Color de texto del botón */
    padding: 10px 20px; /* Espaciado dentro del botón */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; /* Transición suave del color de fondo al pasar el mouse */
  
    /* Estilos cuando el mouse está sobre el botón */
    &:hover {
      background-color: #5482b3; /* Cambia el color de fondo al pasar el mouse */
    }
  }