.parallax-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
 
 
}

.parallax-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}