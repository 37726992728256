.parallax-container {
  width: 100%;
  height: 100vh; /* Tamaño de pantalla completa */
  overflow: hidden;
  display: flex;
  background-color: blanchedalmond;
  margin: 0px;
  /* Ajusta los estilos según tus necesidades */
}
.parallax-images {
  width: 100%;
  height: 118%;
  display: flex;
  touch-action: none;

}.Logo{
  height: 50px;
  width: auto;
}
.right-column {
    flex: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo transparente con opacidad */
    color: #000; /* Color del texto en el menú */
    padding: 20px; /* Añade espacio alrededor del menú */
  }
  .column-item{
    touch-action: none; /* Deshabilita el zoom en dispositivos táctiles */
    user-zoom: no; /* Deshabilita el zoom en general */
    margin: 0px;
  }
  .button1 {
    background-color: #475a66; /* Cambia el color de fondo del botón */
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer; 
    width: 100px;
    height: 100px;
       
  }
  .sub-menu {
    display: none;
    flex-direction: column; /* Cambia la dirección a "column" para que aparezca a la izquierda */
    position: absolute; /* Asegura que el submenú esté posicionado de manera absoluta */
    background-color: #fff;
    z-index: 1;
  }
  
  .sub-menu button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 10px;
    cursor: pointer;
  }
  
  .sub-menu-item {
    padding: 5px; /* Espaciado interno de cada elemento del submenú */
    border-bottom: 1px solid #ddd; /* Borde inferior de los elementos */
    color: #333; /* Color de texto */
    text-align: center; /* Alineación del texto al centro */
    cursor: pointer; /* Cambia el cursor al pasar sobre los elementos */
  }
  .icon {
    /* Establece el tamaño del icono y otros estilos deseados */
    width: 50px; /* Ajusta el tamaño según tus preferencias */
    height: 50px; /* Ajusta el tamaño según tus preferencias */
    position: absolute; /* Coloca el icono en posición absoluta para que no afecte al flujo de los otros elementos */
    top: 10px; /* Ajusta la posición vertical según tus preferencias */
    right: 10px; /* Ajusta la posición horizontal según tus preferencias */
  }