.scene-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

a-scene {
  position: relative;
}

a-sky {
  width: 100%;
  height: 100%;
}

a-camera {
  position: absolute;
  top: 0;
  left: 0;
}

a-circle {
  cursor: pointer;
}

/* Estilos adicionales según tu necesidad */
a-circle:hover {
  scale: 1.1;
  opacity: 0.9;
  transition: 0.2s;
}